import React from "react"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"

import FeaturedSlider from "../featured"

const Featured = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t("featured")} />
      <FeaturedSlider />
    </>
  )
}

export default Featured
