import clsx from "clsx"
import React from "react"
import styled, { keyframes } from "styled-components"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

const BlurKeyframes = keyframes`
  0% {
    filter: blur(4px);
  }

  40% {
    filter: blur(0px);
  }

  60% {
    filter: blur(3px);
  }

  100% {
    filter: blur(0px);
  }
`

const FrameWrapper = styled.figure`
  margin: 0;
  position: relative;
  display: inline-block;
  overflow: hidden;

  transform-style: preserve-3d;
  transform-origin: center;
  transform: scale(1.05);
  transition: transform 700ms;
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(255, 255, 255, 1);
  outline: 1px solid transparent;

  &.active {
    transform: scale(1);
  }

  .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
    opacity: 0;
    transition: transform 700ms, opacity 700ms;
    object-fit: fill;
  }

  .image {
    transition: transform 700ms;
    transform: scale(1.05);
    filter: blur(4px);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  &.active .image {
    transform: scale(1);
    filter: blur(0px);
    animation: ${BlurKeyframes} 800ms ease-in;
  }

  img {
    margin: 0;
  }
`

interface Props {
  active: boolean
  image?: string | IGatsbyImageData
  className?: string
}

const FramedImage = ({ active, image, className }: Props): JSX.Element => {
  const optimisedImage = typeof image === "object" ? getImage(image) : undefined

  return (
    <FrameWrapper className={clsx(active ? "active" : "", className)}>
      {typeof image === "string" ? (
        <img className="image" src={image || "/images/sample.jpg"} />
      ) : optimisedImage ? (
        <GatsbyImage
          image={optimisedImage}
          alt={`Film Talents`}
          imgClassName="image"
        />
      ) : null}
      {/* <img className="frame" src="/images/border-inverted.png" /> */}
    </FrameWrapper>
  )
}

export default FramedImage
