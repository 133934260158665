import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import lottie, { AnimationItem } from "lottie-web"
import { IGatsbyImageData } from "gatsby-plugin-image"

import FramedImage from "../components/FramedImage"

import Scenaristes from "../../static/lotties/scenaristes.json"
import { useStaticQuery, graphql } from "gatsby"

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  overflow: hidden;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  h1 {
    grid-row: 3;
    grid-column: 2;
    text-align: center;
    font-size: 42px;
    letter-spacing: 5px;
    font-family: "Minion Pro";
    font-weight: normal;
    font-style: normal;
  }

  @media only screen and (max-width: 800px) {
    padding: 150px 0px 50px;
    grid-template-rows: 1fr 160px 160px 1fr;
    grid-template-columns: 20px auto 20px;
  }
`

const LottieContainer = styled.div`
  position: absolute;
  max-width: 100%;
  margin-left: 0px;
  pointer-events: none;
`

interface Props {
  active: boolean
}

const imageQuery = graphql`
  query {
    file(relativePath: { eq: "featured/marion-doussot.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
  }
`

const MarionDoussot = ({ active }: Props): JSX.Element => {
  const imageData: { file: IGatsbyImageData } = useStaticQuery(imageQuery)

  const lottieRef = useRef<AnimationItem | null>(null)
  const lottieContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottieRef.current = lottie.loadAnimation({
        container: lottieContainerRef.current,
        loop: false,
        autoplay: false,
        animationData: Scenaristes,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax",
        },
      })
    }
    const destroy = (): void => {
      lottieRef.current?.destroy()
    }

    if (lottieRef.current && active) {
      lottieRef.current.goToAndPlay(0)

      lottieRef.current.addEventListener("enterFrame", function (animation) {
        if (lottieRef.current && animation.currentTime > 100) {
          lottieRef.current.pause()
        }
      })
    }

    return (): void => {
      destroy()
    }
  }, [active])

  return (
    <SlideWrapper>
      <SlideContent>
        <a
          href="http://www.agencesartistiques.com/Fiche-Artiste/556950-marion-doussot.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1>
            MARION<br></br>DOUSSOT
          </h1>
        </a>
        <LottieContainer ref={lottieContainerRef} />
      </SlideContent>
      <FramedImage active={active} image={imageData.file} />
    </SlideWrapper>
  )
}

export default MarionDoussot
