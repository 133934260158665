import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import lottie, { AnimationItem } from "lottie-web"
import { useStaticQuery, graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

import FramedImage from "../components/FramedImage"

import ScenaristesLottieData from "../../static/lotties/scenaristes.json"

const SlideContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  overflow: hidden;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const StyledFrameImage = styled(FramedImage)`
  grid-column: 1 / 3;
  grid-row: 1;
  height: 100%;
`

const SlideContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;

  grid-column: 2;
  grid-row: 1;

  h1 {
    text-align: center;
    font-size: 68px;
    letter-spacing: 5px;
    font-family: "Minion Pro";
    font-weight: normal;
    font-style: normal;
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 800px) {
    grid-row: 2;
    grid-column: 1 / 2;
  }
`

const LottieContainer = styled.div`
  margin-top: -70px;
  margin-right: -70px;
  position: absolute;
  pointer-events: none;
  @media only screen and (max-width: 800px) {
    transform: translate(0);
  }
`

const imageQuery = graphql`
  query {
    file(relativePath: { eq: "featured/fanny-herrero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
  }
`

interface Props {
  active: boolean
}

const FannyHerrero = ({ active }: Props): JSX.Element => {
  const imageData: { file: IGatsbyImageData } = useStaticQuery(imageQuery)

  const lottieRef = useRef<AnimationItem | null>(null)
  const lottieContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottieRef.current = lottie.loadAnimation({
        container: lottieContainerRef.current,
        loop: false,
        autoplay: false,
        animationData: ScenaristesLottieData,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax",
        },
      })
    }
    const destroy = (): void => {
      lottieRef.current?.destroy()
    }

    if (lottieRef.current && active) {
      lottieRef.current.goToAndPlay(0)

      lottieRef.current.addEventListener("enterFrame", function (animation) {
        if (lottieRef.current && animation.currentTime > 100) {
          lottieRef.current.pause()
        }
      })
    }

    return (): void => {
      destroy()
    }
  }, [active])

  return (
    <SlideContainer>
      <StyledFrameImage active={active} image={imageData.file} />
      <SlideContent>
        <a
          href="https://www.imdb.com/name/nm2638139/?ref_=nv_sr_srsg_0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h1>
            FANNY<br></br>HERRERO
          </h1>
        </a>
        <LottieContainer ref={lottieContainerRef} />
      </SlideContent>
    </SlideContainer>
  )
}

export default FannyHerrero
