import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import lottie, { AnimationItem } from "lottie-web"
import { IGatsbyImageData } from "gatsby-plugin-image"

import FramedImage from "../components/FramedImage"

import RealisateursLottieData from "../../static/lotties/realisateurs.json"
import { useStaticQuery, graphql } from "gatsby"

const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  overflow: hidden;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  align-items: center;
  justify-content: center;

  a {
    display: block;
  }

  h1 {
    grid-row: 2 / 4;
    grid-column: 2;
    text-align: center;
    font-size: 32px;
    letter-spacing: 5px;
    font-family: "Minion Pro";
    font-weight: normal;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 800px) {
    padding: 150px 0px 50px;
    grid-template-rows: 1fr 190px 190px 1fr;
    grid-template-columns: 20px auto 20px;
  }
`

const LottieContainer = styled.div`
  position: absolute;
  pointer-events: none;
  margin-right: -180px;
  width: 600px;
  max-width: 100%;
  @media only screen and (max-width: 800px) {
    margin-right: 0;
  }
`

const StyledFrame = styled(FramedImage)`
  grid-column: 2 / 4;
  @media only screen and (max-width: 800px) {
    grid-column: 1 / 4;
  }
`

interface Props {
  active: boolean
}

const imageQuery = graphql`
  query {
    file(relativePath: { eq: "featured/alexis-langlois.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
      }
    }
  }
`

const AlexisLanglois = ({ active }: Props): JSX.Element => {
  const imageData: { file: IGatsbyImageData } = useStaticQuery(imageQuery)

  const lottieRef = useRef<AnimationItem | null>(null)
  const lottieContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (lottieContainerRef.current) {
      lottieRef.current = lottie.loadAnimation({
        container: lottieContainerRef.current,
        loop: false,
        autoplay: false,
        animationData: RealisateursLottieData,
        rendererSettings: {
          preserveAspectRatio: "xMaxYMax",
        },
      })
    }
    const destroy = (): void => {
      lottieRef.current?.destroy()
    }

    if (lottieRef.current && active) {
      lottieRef.current.goToAndPlay(0)

      lottieRef.current.addEventListener("enterFrame", function (animation) {
        if (lottieRef.current && animation.currentTime > 100) {
          lottieRef.current.pause()
        }
      })
    }

    return (): void => {
      destroy()
    }
  }, [active])

  return (
    <SlideWrapper>
      <SlideContent>
        <h1>
          <a
            href="https://www.imdb.com/name/nm5652096/?ref_=fn_al_nm_1"
            target="_blank"
            rel="noopener noreferrer"
          >
            ALEXIS<br></br>LANGLOIS
          </a>
        </h1>
        <LottieContainer ref={lottieContainerRef} />
      </SlideContent>
      <StyledFrame active={active} image={imageData.file} />
    </SlideWrapper>
  )
}

export default AlexisLanglois
