import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import SwiperClass from "swiper/types/swiper-class"
import "swiper/swiper.min.css"

import { usePageTransitionContext } from "../context/PageTransitionContext"

import AlexisLanglois from "./AlexisLanglois"
import MarionDoussot from "./MarionDoussot"
import FannyHerrero from "./FannyHerrero"
import MarineJossetAndIrisDeJessey from "./MarineJossetAndIrisDeJessey"
import BertrandBonello from "./BertrandBonello"
import CharlotteSanson from "./CharlotteSanson"
import IsmaelElIraki from "./IsmaelElIraki"

SwiperCore.use([Autoplay])

const FeaturedContainer = styled.div`
  position: relative;
  opacity: 0;
`

const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100vh;
`

const FeaturedSlider = (): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState<number | null>(0)
  const loaded = useRef(false)
  const sliding = useRef(false)
  const slidingTimeout = useRef(0)
  const swiperRef = useRef<SwiperClass | null>(null)
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const { entering, exiting, timeElapsed } = usePageTransitionContext()

  useEffect(() => {
    const duration = (timeElapsed?.current ?? 1500) / 1000
    if (entering) {
      if (contentWrapperRef.current) {
        gsap.to(contentWrapperRef.current, {
          duration: duration - 0.1,
          ease: "power3.inOut",
          opacity: 1,
          delay: 0.1,
        })
      }
      loaded.current = true
    }

    if (exiting && loaded.current) {
      gsap.to(contentWrapperRef.current, {
        duration,
        ease: "power3.inOut",
        opacity: 0,
      })
    }
  }, [entering, exiting, timeElapsed])

  useEffect(() => {
    const onScroll = (e: WheelEvent) => {
      if (e.deltaY > 0 && !sliding.current) {
        swiperRef.current?.slideNext()
      }
      if (e.deltaY < 0 && !sliding.current) {
        swiperRef.current?.slidePrev()
      }
    }

    window.addEventListener("wheel", onScroll)

    return () => {
      window.removeEventListener("wheel", onScroll)
      window.clearTimeout(slidingTimeout.current)
    }
  }, [])

  return (
    <FeaturedContainer ref={contentWrapperRef}>
      <SwiperContainer
        autoplay={{
          delay: 7000,
          disableOnInteraction: true,
        }}
        speed={700}
        loop={true}
        preventClicksPropagation={false}
        preventClicks={false}
        onSlideChangeTransitionStart={() => {
          sliding.current = true
        }}
        onSlideChangeTransitionEnd={props => {
          setCurrentSlide(props.activeIndex)
          slidingTimeout.current = window.setTimeout(() => {
            sliding.current = false
          }, 1000)
          if (props.activeIndex === 6) {
            props.autoplay.stop()
          }
        }}
        onSliderFirstMove={() => {
          setCurrentSlide(null)
        }}
        onSlideResetTransitionEnd={props => {
          setCurrentSlide(props.activeIndex)
        }}
        onInit={swiper => {
          swiperRef.current = swiper
        }}
      >
        <SwiperSlide>
          <AlexisLanglois
            active={(currentSlide === 1 || currentSlide === 8) && !exiting}
          />
        </SwiperSlide>
        <SwiperSlide>
          <FannyHerrero active={currentSlide === 2 && !exiting} />
        </SwiperSlide>
        <SwiperSlide>
          <MarineJossetAndIrisDeJessey
            active={currentSlide === 3 && !exiting}
          />
        </SwiperSlide>
        <SwiperSlide>
          <BertrandBonello active={currentSlide === 4 && !exiting} />
        </SwiperSlide>
        <SwiperSlide>
          <CharlotteSanson active={currentSlide === 5 && !exiting} />
        </SwiperSlide>
        <SwiperSlide>
          <MarionDoussot active={currentSlide === 6 && !exiting} />
        </SwiperSlide>
        <SwiperSlide>
          <IsmaelElIraki active={currentSlide === 7 && !exiting} />
        </SwiperSlide>
      </SwiperContainer>
    </FeaturedContainer>
  )
}

export default FeaturedSlider
